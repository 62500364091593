<template>
<cui-acl  :roles="['HRcreateUsersAll', 'HRcreateUsersDepartment']" redirect>
    <new-user-form :data="data" />
</cui-acl>
</template>

<script>
import newUserForm from '@/components/custom/forms/newUser'
import cuiAcl from '@/components/cleanui/system/ACL'
export default {
  components: {
    newUserForm,
    cuiAcl,
  },
  data() {
    return {
      data: {
        positions: [{ type: null }],
      },
    }
  },
}
</script>

<style>

</style>
