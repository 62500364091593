<template>
    <a-form @submit.prevent="createUser2" v-if="loaded" :form="form">
        <h2>Onboarding Sky Gruppen</h2>
        <div class="card">
        <div class="card-body">
          <h5 class="text-black mt-2 mb-3">Ansettelsestype</h5>
          <div class="row mb-3" v-if="$store.state.user.companies.length > 1">
            <div class="col-md-6">
              <a-form-item label="Velg selskap">
                <a-select :options="companies" v-model="selectedCompany"/>
              </a-form-item>
            </div>
          </div>
          <div class="row">
              <div class="col-lg-12">
                <tab-selector v-model="position.type" :values="positionsSelector.filter(el => el.company === selectedCompany)" v-if="$store.state.user.companies.length > 1"/>
                <tab-selector v-model="position.type" :values="positionsSelector" v-else />
              </div>
          </div>
        </div>
        </div>
        <div class="card">
        <div class="card-body">
          <div class="row" v-if="position.type === 'custom'">
          <div class="col-md-6">
            <a-form-item label="Stillingsnavn">
            <a-input placeholder="Stillingsnavn" v-model="customType"/>
            </a-form-item>
          </div>
          <div class="col-md-6">
            <a-form-item label="Selskap">
            <a-input placeholder="Selskap" v-model="position.company"/>
            </a-form-item>
          </div>
          <div class="col-md-6">
            <a-form-item label="Prosjekt">
            <a-input placeholder="Prosjekt" v-model="position.project"/>
            </a-form-item>
          </div>
          <div class="col-md-6">
            <a-form-item label="Stillingstype">
            <a-input placeholder="Fulltid/Deltid" v-model="position.employmentType"/>
            </a-form-item>
          </div>
          </div>
          <div class="row">
            <div class="col-md-4" v-if="['HRcreateUsersAll'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
              <a-form-item label="Avdeling">
                <a-select
                  v-model="position.department"
                  :options="avDepartments"
                />
              </a-form-item>
            </div>
            <div class="col-md-4">
                <a-form-item :label="$t('userInfo.startDate')" :required="true">
                <a-date-picker :default-value="moment(new Date(), 'YYYY-MM-DD')"  v-model="position.startDate"/>
                </a-form-item>
            </div>
          </div>
          <div class="row" v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
            <div class="col-md-4">
              <a-form-item label="Stillingsprosent (i %)" :required="true">
                <a-input
                  :value="position.percentage"
                  placeholder="Prosent Stilling"
                  :max-length="3"
                />
              </a-form-item>
            </div>
            <div class="col-md-4">
              <a-form-item label="Garanti / Fastlønn (i kr)" :required="true">
                <a-input
                  :value="position.fixedMonthly"
                  placeholder="Månedslønn"
                />
              </a-form-item>
            </div>
          </div>
        </div>
        </div>
        <div class="card">
        <div class="card-body">
            <h5 class="text-black mt-2 mb-3">Personalia</h5>
            <div class="row">
            <div class="col-lg-6">
                <a-form-item :label="$t('userInfo.name')" :required="true">
                <a-input
                  :placeholder="$t('userInfo.name')"
                  v-decorator="[
                    'name',
                    {
                      rules: [
                        { required: true, message: 'Venligst skriv et navn' },
                        { validator: generateUsernames},
                      ],
                    }
                  ]"
                />
                </a-form-item>
            </div>
            <div class="col-lg-6">
                <a-form-item :label="$t('userInfo.surname')" :required="true">
                <a-input
                  :placeholder="$t('userInfo.surname')"
                  v-decorator="[
                    'surname',
                    {
                      rules: [
                        { required: true, message: 'Venligst skriv et etternavn' },
                        { validator: generateUsernames},
                      ],
                    }
                  ]"
                />
                </a-form-item>
            </div>
            <div class="col-lg-6">
                <a-form-item :label="$t('userInfo.email')" :required="true" has-feedback>
                <a-input
                  :placeholder="$t('userInfo.email')"
                  v-decorator="[
                    'email',
                    {
                      rules: [
                        // {
                        //   type: 'email',
                        //   message: 'Dette er ikke et gyldig e-post!',
                        // },
                        { validator: validateEmail },
                        { required: true, message: 'Venligst skriv et navn' },
                      ],
                    }
                  ]"
                />
                </a-form-item>
            </div>
            <div class="col-lg-6">
              {{ areaCode }}
            </div>
            <div class="col-lg-6">
              <a-form-item :label="$t('userInfo.phone')" :required="true">
                <country-selector style="width: 100px; display: inline-block;"
                  v-model="areaCode"
                  @change="console.log(areaCode)"
                />
                <a-input :placeholde="$t('userInfo.phone')" style="width: calc(100% - 100px)"
                  v-decorator="[
                    'phone',
                    {
                      rules: [
                        // {
                        //   type: 'email',
                        //   message: 'Dette er ikke et gyldig e-post!',
                        // },
                        { validator: validateEmail },
                        { required: true, message: 'Venligst skriv et navn' },
                      ],
                    }
                  ]"
                />
              </a-form-item>
            </div>
            <div class="col-lg-6">
              <a-form-item :label="$t('userInfo.socialsecurity')" :required="true">
                <a-input :placeholder="$t('userInfo.socialsecurity')" v-model="data.personalNumber"  @change="fnrValidate(data.personalNumber)" :maxLength="12" :class="{ validfield: fnrValid, invalidfield: fnrError }"/>
              </a-form-item>
            </div>
            <div class="col-lg-6">
                <a-form-item :label="$t('userInfo.bankaccount')" :required="true" has-feedback>
                <a-input
                  :placeholder="$t('userInfo.bankaccount')" :maxLength="13"
                  v-decorator="[
                    'bankAccount',
                    {
                      rules: [
                        { required: true, message: 'Venligst skriv inn et gyldig kontonummer' },
                        { validator: validateBank },
                      ],
                    }
                    ]"
                />
                </a-form-item>
            </div>
            <div class="col-md-6">
                <a-form-item :label="$t('userInfo.adress')" :required="true">
                <a-input :placeholder="$t('userInfo.adress')" v-model="data.adress"/>
                </a-form-item>
            </div>
            <div class="col-md-2">
                <a-form-item :label="$t('userInfo.zip')" :required="true">
                <a-input v-model="data.zip" :maxLength="4" @change="data.city = findCity(data.zip)"/>
                </a-form-item>
            </div>
            <div class="col-md-4">
                <a-form-item :label="$t('userInfo.city')" >
                {{ data.city }}
                </a-form-item>
            </div>
            </div>
        </div>
        </div>
        <div class="card">
        <div class="card-body">
          <a-form-item label="Notater">
            <a-textarea placeholder="Notater" :rows="4" v-model="data.notes"/>
          </a-form-item>
        </div>
        </div>
        <div class="card" v-if="['underDevelopment'].some(r => roles.indexOf(r) >= 0)">
        <div class="card-body">
            <h5 class="text-black mt-2 mb-3">Brukere</h5>
            <div class="row">
              <div class="col-md-6" v-if="['underDevelopment'].some(r => roles.indexOf(r) >= 0)">
                <a-form-item label="Lage Office 365 bruker?">
                <a-radio-group v-model="create365">
                    <a-radio :style="radioStyle" :value="true">
                    Ja
                    </a-radio>
                    <a-radio :style="radioStyle" :value="false">
                    Nei
                    </a-radio>
                </a-radio-group>
                </a-form-item>
              </div>
              <div class="col-md-6">
                <a-form-item label="Bedrifts e-post">
                <a-input :placeholder="$t('userInfo.email')" type="email" v-model="data.companyEmail" />
                </a-form-item>
              </div>
            </div>
        </div>
        </div>
        <div class="card">
        <div class="card-body">
            <h5 class="text-black mt-2 mb-3">Kontrakt</h5>
            <div class="row">
              <div class="col-md-6">
                <a-form-item label="Lage Kontrakt?">
                <a-radio-group v-model="createContract">
                    <a-radio :style="radioStyle" :value="true">
                    Ja
                    </a-radio>
                    <a-radio :style="radioStyle" :value="false">
                    Nei
                    </a-radio>
                </a-radio-group>
                </a-form-item>
              </div>
            </div>
            <div class="row" v-if="['underDevelopment'].some(r => roles.indexOf(r) >= 0)">
              <div class="col-md-6">
                <a-form-item label="Egendefinert AnsattID?">
                <a-radio-group v-model="createCustomID">
                    <a-radio :style="radioStyle" :value="true">
                    Ja
                    </a-radio>
                    <a-radio :style="radioStyle" :value="false">
                    Nei
                    </a-radio>
                </a-radio-group>
                </a-form-item>
              </div>
              <div class="col-md-6">
                <a-form-item label="Egendefinert ansattID">
                <a-input type="text" v-model="customID" />
                </a-form-item>
              </div>
          </div>
          </div>
        </div>
          <div class="card" v-if="['underDevelopment'].some(r => roles.indexOf(r) >= 0)">
          <div class="card-body">
            <h5 class="text-black mt-2 mb-3">Referert</h5>
            <div class="row">
              <div class="col-md-6">
                <a-form-item label="Har ansatten blitt referert?">
                <a-radio-group v-model="isReferred">
                    <a-radio :style="radioStyle" :value="true">
                    Ja
                    </a-radio>
                    <a-radio :style="radioStyle" :value="false">
                    Nei
                    </a-radio>
                </a-radio-group>
                </a-form-item>
              </div>
              <div class="col-md-6" v-if="isReferred">
                <a-form-item label="Referert av">
                <a-auto-complete
                  v-model="data.referredBy"
                  :data-source="users"
                  :filter-option="filterOption"
                  style=""
                  placeholder="finn ansatt..."
                />
                </a-form-item>
              </div>
            </div>
        </div>
        </div>
      <div class="form-actions mt-10">
      <a-button style="width: 150px;" type="primary" htmlType="submit" class="mr-3" :loading="loading">Onboard Ansatt</a-button>
      <a-button style="width: 150px;" type="error" class="mr-3" @click="resetForm">Reset</a-button>
      </div>
    </a-form>
</template>

<script>
import { firestore, functions } from '@/services/firebase'

import moment from 'moment'
import { mapState } from 'vuex'
import { openNotification, create365API } from '@/services/powerAutomate'
import { calculateBirthday, findCity, createContract, selectAvDepartments, validateBank } from '@/services/common/hr'
import validator from 'norsk-validator'
import tabSelector from '@/components/custom/visual/tabSelector'
import countrySelector from '@/components/custom/visual/countrySelector'

function formatNumber(value) {
  value += ''
  const list = value.split('.')
  const prefix = list[0].charAt(0) === '-' ? '-' : ''
  let num = prefix ? list[0].slice(1) : list[0]
  let result = ''
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`
    num = num.slice(0, num.length - 3)
  }
  if (num) {
    result = num + result
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`
}

export default {
  components: {
    tabSelector,
    countrySelector,
  },
  props: ['data'],
  computed: {
    ...mapState(['user']),
  },
  data() {
    return {
      areaCode: '',
      loaded: false,
      roles: this.$store.state.user.roles,
      role: this.$store.state.user.role,
      value: null,
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      createContract: true,
      create365: true,
      loading: false,
      fnrValid: false,
      fnrError: false,
      bankValid: false,
      bankError: false,
      customType: '',
      customID: '',
      createCustomID: false,
      isReferred: false,
      users: [],
      practicant: false,
      showPercentage: false,
      positionsSelector: [],
      contractTypes: [],
      selectedContract: {},
      company: {},
      avDepartments: [],
      companies: [],
      selectedCompany: '',
      position: {},
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'newUser' })
  },
  mounted() {
    this.$store.state.user.companies.forEach(company => {
      firestore.collection('settings').doc('companies').collection('companies').doc(company).get()
        .then(doc => {
          this.companies.push({
            label: doc.data().displayName,
            value: doc.id,
            emailDomain: doc.data().emailDomain,
          })
        })
    })
    if (this.$store.state.user.companies.length === 1) {
      this.selectedCompany = this.$store.state.user.companies[0]
    }
    firestore.collection('settings').doc('projects').collection('projects')
      .get()
      .then(snapshot => {
        let i = 1
        snapshot.forEach(doc1 => {
          if ([doc1.data().company].some(r => this.$store.state.user.companies.indexOf(r) >= 0)) {
            firestore.collection('settings').doc('contracts').collection('contracts').where('project', '==', doc1.id).orderBy('title', 'asc').get()
              .then(snapshot => {
                const children = []
                snapshot.forEach(doc2 => {
                  if ((['HRcreateUsersDepartment'].some(r => this.$store.state.user.roles.indexOf(r) >= 0) && [this.$store.state.user.department].some(r => doc2.data().department.indexOf(r) >= 0)) ||
                      ['HRcreateUsersAll'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
                    if ([doc2.data().role].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
                      const child = {
                        value: doc2.data().title,
                        department: doc2.data().department,
                        company: doc2.data().company,
                        project: doc1.data().name,
                        docTemplate: doc2.data().docTemplate,
                        defaultRoles: doc2.data().defaultRoles,
                        positionTitle: doc2.data().positionTitle,
                        employmentType: doc2.data().employmentType,
                        contractId: doc2.id,
                        percentage: doc2.data().percentage,
                        fixedMonthly: doc2.data().fixedMonthly,
                      }
                      this.contractTypes.push(child)
                      children.push(child)
                    }
                  }
                })
                if (children.length) {
                  this.positionsSelector.push({
                    title: doc1.data().name,
                    company: doc1.data().company,
                    key: i,
                    children,
                  })
                  i += 1
                }
              })
          }
        })
        console.log(this.contractTypes)
        this.loaded = true
      })
    // firebase.firestore().collection('users').where('status', '==', 'active').get()
    //   .then(snapshot => {
    //     snapshot.forEach(doc => {
    //       const user = {
    //         value: String(doc.data().employeeID),
    //         text: String(doc.data().fullName),
    //         key: String(doc.data().employeeID),
    //       }
    //       this.users.push(user)
    //     })
    //   })
  },
  methods: {
    moment,
    validateBank,
    formatNumber,
    selectCompany(companyId) {
      console.log(companyId)
    },
    createUser2(e) {
      // improove table layout
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          console.log(values)
        }
      })
    },
    async createUser() {
      this.fnrValidate(this.data.personalNumber)
      this.bankValidate()
      if (this.fnrValid && this.bankValid && this.position.type) {
        this.loading = true
        const data = this.data
        const position = this.position
        let fnrExists = 0
        await firestore.collection('users').where('personalNumber', '==', data.personalNumber).get().then(snapshot => {
          fnrExists = snapshot.docs.length
        })
        if (!fnrExists) {
          position.startDate = moment(position.startDate).format('YYYY-MM-DD')
          data.companies = [this.selectedCompany]
          data.name = data.name.trim()
          data.surname = data.surname.trim()
          data.adress = data.adress.trim()
          data.fullAddress = data.adress.concat(', ', data.zip, ' ', data.city)
          data.fullName = data.name.concat(' ', data.surname)
          data.sentBy = this.$store.state.user.email2
          data.department = position.department
          position.signedContract = false
          if (position.type === 'custom') position.type = this.customType
          data.status = 'onboarding'
          // get employeeID
          firestore.collection('variables').doc('employeeID').get()
            .then(doc => {
              this.data.employeeID = !this.createCustomID ? String(Number(doc.data().lastID) + 1) : String(this.customID)
              position.id = String(Number(doc.data().lastPositionID) + 1)
              console.log(doc.data(), this.data.employeeID)
              // add new user to firestore
              console.log(data)
              data.positions = []
              data.positions.push(position)
              return firestore.collection('users').add(data)
            }).then(() => {
              // update new employeeID on database
              if (!this.createCustomID) {
                return firestore.collection('variables').doc('employeeID').set({
                  lastID: String(this.data.employeeID),
                  lastPositionID: String(position.id),
                })
              } else {
                return firestore.collection('variables').doc('employeeID').update({
                  lastPositionID: position.id,
                })
              }
            }).then(() => {
              // contract API
              console.log('create contract')
              if (this.createContract) return createContract(data, position, 0)
              return true
            }).then(response => {
              openNotification(response.message)
              openNotification('Ansatt oprettett', 'Ansatten har blitt oprettett.')
              if (this.create365) return create365API(data)
              return true
            }).then(() => {
              this.loading = false
              this.resetForm()
              this.fnrValid = false
              this.bankValid = false
            }).catch(err => {
              openNotification('Feil ved oprettelse av ansatt', 'Vennligst sjekk at alt informasjon stemmer eller prøv igjen senere')
              this.loading = false
              console.log(err)
            })
        } else {
          openNotification('Fødselsnummer allerede i database', 'En person med samme fødselsnummer er allerede registrert i databasen. Vennliigst prøv igjen med en annen, eller reaktiver gammel bruker.')
          this.loading = false
        }
      } else {
        openNotification('Ugyldig Fødselsnummer, d-nummer eller kontonummer', 'Fødselsnummer, D-nummer eller kontonummer er ugyldig, vennligst rett opp.')
      }
    },
    resetForm() {
      this.data = {}
      this.position =
        {
          type: null,
          startDate: moment(new Date(), 'YYYY-MM-DD'),
        }
      this.customID = ''
    },
    findCity,
    fnrValidate(fnr) {
      let data = fnr
      data = data.replace(/[\s.]/g, '')
      this.data.personalNumber = data
      if (data.length === 11) {
        if (validator.fodselsnummer(this.data.personalNumber)) {
          this.data.birthdate = calculateBirthday(data)
          console.log(this.data)
          this.fnrError = false
          this.fnrValid = true
        } else {
          this.fnrValid = false
          this.fnrError = true
        }
      } else {
        this.fnrValid = false
        this.fnrError = true
      }
    },
    async validateEmail(rule, value, callback) {
      const data = value.toLowerCase()
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (data.match(re)) {
        const call = functions.httpsCallable('emailAddressVerify')
        const res = await call(value)
        console.log(res)
        if (res.data.valid) {
          callback()
        } else {
          callback(new Error(`E-post er feil, årsak: ${res.data.reason}`))
        }
      } else {
        callback(new Error('E-post er ugyldig!'))
      }
      this.form.setFieldsValue({ email: data })
    },
    bankValidate() {
      let data = this.data.bankAccount
      data = data.replace(/[\s.]/g, '')
      this.data.bankAccount = data
      if (data.length === 11) {
        if (validator.kontonummer(data)) {
          this.bankError = false
          this.bankValid = true
        } else {
          this.bankValid = false
          this.bankError = true
        }
      } else {
        this.bankValid = false
        this.bankError = true
      }
    },
    selectProject (e) {
      console.log(e)
      const obj = this.contractTypes.find(o => o.value === e)
      console.log(obj)
      this.position.project = obj.project
      this.position.percentage = obj.percentage
      this.position.fixedMonthly = obj.fixedMonthly
      this.data.title = obj.positionTitle
      this.data.roles = obj.defaultRoles
      if (['HRcreateUsersAll'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
        this.avDepartments = selectAvDepartments(obj.department)
      } else {
        this.position.department = this.$store.state.user.department
      }
      this.position.company = obj.company
      this.position.employmentType = obj.employmentType
      this.position.contractId = obj.contractId
      this.generateUsernames()
    },
    // to be remade as standard function
    generateUsernames () {
      if (this.data.name && this.data.surname && this.position.type) {
        const name = this.data.name.toLowerCase().replace(/æ/gi, 'ae').replace(/ø/gi, 'oe').replace(/å/gi, 'aa').replace(/[^\w\s]/gi, '')
        const surname = this.data.surname.toLowerCase().replace(/æ/gi, 'ae').replace(/ø/gi, 'oe').replace(/å/gi, 'aa').replace(/[^\w\s]/gi, '')
        const username = name.slice(0, 3).concat('.', surname.slice(0, 3))
        const domain = this.companies.find(el => el.value === this.position.company).emailDomain
        // Office 365 email
        this.data.companyEmail = username.concat('@', domain)
        // Leaddesk Username
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
      )
    },
    onChange(e) {
      const { value } = e.target
      const reg = /^-?[0-9]*(\.[0-9]*)?$/
      if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
        this.value = value
      }
    },
    // '.' at the end or only '-' in the input box.
    onBlur(value) {
      const { onChange } = this
      if (value.charAt(value.length - 1) === '.' || value === '-') {
        onChange({ value: value.slice(0, -1) })
      }
    },
  },
  watch: {
    // whenever question changes, this function will run
    'position.type'(newValue) {
      this.selectProject(newValue)
    },
  },
}
</script>

<style scoped>
  .validfield {
    border-color: green!important
  }
  .invalidfield {
    border-color: red!important
  }
</style>
