<template>
  <div>
    <a-select :options="countriesList" show-search v-bind="modelValue" @change="emitnow"/>
  </div>
</template>

<script>
import { countries } from 'countries-list'

export default {
  props: ['modelValue'],
  emits: ['update:modelValue'],
  data() {
    return {
      countriesList: [],
    }
  },
  methods: {
    emitnow(e) {
      this.$emit('update:modelValue', e)
    },
  },
  mounted() {
    const c = countries
    Object.keys(c).forEach(key => {
      const country = countries[key]
      const value = '+'.concat(country.phone)
      if (!this.countriesList.find(el => el.value === value)) {
        this.countriesList.push({
          label: country.emoji.concat(' +', country.phone),
          value,
          name: country.name,
        })
      }
    })
  },
}
</script>
